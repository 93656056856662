import React, { Component } from 'react';

// import { Document } from 'react-pdf';
import { GridListViewContext } from './GridListViewProvider';
import YouTube from 'react-youtube';
import { __ } from '../i18n';

// import {createBrowserHistory} from 'history';

class OpenMaterial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFilm: props.item.materialType === 'film',
      hasFilmBackground: !!(
        props.item.materialType === 'film' &&
        props.item.infomaterial_image.infomaterialImage &&
        props.item.infomaterial_image.infomaterialImage.sourceUrl
      ),
      isModalOpen: false
    };
    this.open = this.open.bind(this);
    this.closeModal = this.closeModal.bind(this);
    // todo: make the browser "back" button close the modal instead of
    // going back in history, so that mobile users don't try to "go back"
    // instead of closing the modal
    // this.history = createBrowserHistory({ basename: '/hjalp-informationsmaterial/' });
    this.contextRef = null;
  }

  componentDidMount() {
    // Add a popstate event listener to handle browser's back button
    window.addEventListener('popstate', this.handlePopState.bind(this));
  }

  componentWillUnmount() {
    // Remove the popstate event listener to avoid memory leaks
    window.removeEventListener('popstate', this.handlePopState.bind(this));
  }

  handlePopState = () => {
    if (this.state.isModalOpen) {
      this.closeModal();
    }
  };

  getInfoTypeId() {
    const kat = this.getCategory();
    return kat.materialkategoriId || null;
  }

  getCategory() {
    const kat = this.props.item.materialkategorier;
    if (!kat) {
      return null;
    }
    if (!kat.nodes || !kat.nodes[0]) {
      return null;
    }
    return kat.nodes[0];
  }

  getMaterialComponent() {
    const videoJson = this.state.isFilm ? this.props.item?.video : '';
    if (videoJson) {
      const video = JSON.parse(videoJson);
      const opts = {
        playerVars: {
          autoplay: 1,
        },
      };
      let Vid;
      switch (video.service) {
        case 'youtube':
          Vid = () => <YouTube videoId={video.id} opts={opts} />;
          break;

        case 'other':
          Vid = () => <iframe src={video.url} />;
          break;

        default:
          Vid = () => <div dangerouslySetInnerHTML={{ __html: video.video }} />;
          break;
      }
      return (
        <div className="video-container">
          <Vid />
        </div>
      );
    }
    if (this.props.item.pdfUrl) {
      return (
        <>
        <iframe
          src={this.props.item.pdfUrl}
          // type="application/pdf"
          width="100%"
          height="100%"
        >
          <p>
            { __('Your browser does not support PDFs.') }
            <a href={this.props.item.pdfUrl}>{ __('Download the PDF.') }</a>
          </p>
        </iframe>
        </>
      );
    }
    return null;
  }

  open(context) {
    const problems = __('Problems opening the document?');
    const download = __('Download here');
    const subtitle = this.props.item.pdfUrl ?
    `${problems} <a href="${this.props.item.pdfUrl}">${download}</a>` :
    '';
    const title = this.props.item.title;
    context.state.modal.open({
      title,
      subtitle,
      content: this.getMaterialComponent()
    });

    // this.history.push('/hej');
    this.setState({ isModalOpen: true });
    this.contextRef = context;
  }

  closeModal() {
    // Close the modal and go back in the history
    this.contextRef.state.modal.close();

    // Use the history object to go back when the modal is closed
    // this.history.back();

    // Set modal state to closed
    this.setState({ isModalOpen: false });
  }

  render() {
    const typeId = this.getInfoTypeId();
    if (!typeId) {
      return null;
    }
    const text = __(`open-${typeId}`);
    const link = this.props.item.link || null;
    if (!link) {
      return null;
    }
    const icon = this.state.isFilm ? <span className="play-icon" /> : '';
    const className = 'button icon-button';

    // const videoJson = this.state.isFilm ? this.props.item?.video : '';
    // if (videoJson) {
    //   const video = JSON.parse(videoJson);
    // }
    const Button = this.state.isFilm ?
        (context) => <button className={className} onClick={() => this.open(context)}>
                      {icon}
                      <span>{text}</span>
                    </button> :
        (context) => <a className={className} href={this.props.item.pdfUrl}>{text}</a>
    return (
      <GridListViewContext.Consumer>
        {(context) => (
          Button(context)
        )}
      </GridListViewContext.Consumer>
    );
  }
}
export default OpenMaterial;
