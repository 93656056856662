import React, { Component } from 'react';

import GridListCategoryFilter from './GridListCategoryFilter';
import GridListOptions from './GridListOptions';
import { GridListViewContext } from './GridListViewProvider';
import { __ } from '../i18n';

const filterIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filter" viewBox="0 0 16 16">
  <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"/>
</svg>`;
export default class GridListHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle(e) {
    this.setState({ isOpen: !this.state.isOpen });
  }

  selectItem(event, callback) {
    this.setState({ isOpen: false });
    callback(event);
  }

  render() {
    const { isOpen } = this.state;
    const className = 'grid-list-header' + (isOpen ? ' is-open' : ' is-closed');
    const btnText = filterIcon + '<span>' + __('Open Filters') + '</span>';
    const productFilterItems = [{
      produktId: '',
      title: __('Show all'),
    }, ...this.props.products];
    const savedProductId = window.sessionStorage.getItem('selectedProduct') || '';
    const selectedProductId = savedProductId ? parseInt(savedProductId) : '';
    const selectedProduct = this.props.products.find((item) => {
      return item.produktId === selectedProductId;
    }) || null;
    const selectedCategory = this.props.categories.find((item) => {
      return item.slug === window.sessionStorage.getItem('selectedCategory');
    }) || null;
    return (
      <GridListViewContext.Consumer>
        {(context) => (
          <>
            {(selectedProduct || selectedCategory) &&
              <div className="selected-filters-wrapper">
                {__('Selected filters')}:
                <div className="selected-filters">
                  {selectedProduct &&
                    <ul className="product-filter">
                      <li>
                        <a data-product-id=""
                          onClick={context.state.chosenProduct.handleProductChange}
                          className="product-filter-link active-choice">
                            <span className="close-btn">&times;</span> {selectedProduct.title}
                        </a>
                      </li>
                    </ul>
                  }
                  {
                  selectedCategory &&
                    <div className="category-filter-item">
                      <a data-category-id=""
                        style={{
                          '--category-filter-color': selectedCategory.MaterialtypInfo
                            ? selectedCategory.MaterialtypInfo.farg
                            : '#cacaca',
                        }}
                        onClick={(e) => this.selectItem(e, context.state.chosenCategory.handleCategoryChange)}
                        className="category-filter-link active-choice">
                        <span className="link-title">
                          <span className="close-btn">&times;</span> {selectedCategory.name}
                        </span>
                      </a>
                    </div>
                  }
                </div>
            </div>
            }

            <button className="open-filters icon-button" onClick={this.toggle} dangerouslySetInnerHTML={{__html: btnText}} />
            <div className={className}>
              <button className="close-filter top" onClick={this.toggle}>{__('Close Filters')}</button>
              <GridListOptions />
              <h2 className="grid-list-title">{__('Select Product')}</h2>
                <>
                  <ul className="product-filter">
                    {productFilterItems.map((item) => {
                      return (
                        <li key={item.produktId}>
                          <a
                            data-product-id={item.produktId}
                            onClick={(e) => this.selectItem(e, context.state.chosenProduct.handleProductChange)}
                            className={`product-filter-link${
                                selectedProductId === item.produktId
                                ? ' active-choice'
                                : ''
                            }`}
                          >
                            {item.title}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="grid-list-filter-options">
                    <h2 className="grid-list-title">{__('Select Type')}</h2>
                    <GridListCategoryFilter
                      chosencategories={this.props.chosencategories}
                      categories={this.props.categories}
                    />
                  </div>
                  <button className="close-filter bottom" onClick={this.toggle}>{__('Close Filters')}</button>
                </>
            </div>
          </>
        )}
      </GridListViewContext.Consumer>
    );
  }
}
