import React, { Component } from 'react';

import { GridListViewContext } from './GridListViewProvider';
import OpenMaterial from './OpenMaterial';
import ReadMore from './ReadMore';

// import { __ } from '../i18n';

/**
 * The `convertHexToRGBA` functions takes a hex code and opacity parameters
 * and transforms this into a RGBA format instead.
 *
 * @param {string} hexCode
 * @param {number} opacity
 * @returns
 */
const convertHexToRGBA = (hexCode, opacity) => {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
};

export default class GridView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isLoaded: true,
      });
    }, 100);

    /**
     * `convertImages` takes the element that is passed in the function below (in this case, the images with an svg),
     * extracts the file contents and puts it inline, so that it can later be manipulated with (S)CSS.
     * @param {Object} query - The element passed.
     */

    const convertImages = (query, callback) => {
      const gridlist = document.querySelector('.grid-list-view');
      const images = gridlist.querySelectorAll(query);

      images.forEach((image) => {
        fetch(image.src)
          .then((res) => res.text())
          .then((data) => {
            const parser = new DOMParser();
            const svg = parser
              .parseFromString(data, 'image/svg+xml')
              .querySelector('svg');

            if (svg) {
              if (image.id) {
                svg.id = image.id;
              }
              if (image.classList) {
                svg.classList = image.classList;
              }
              image.parentNode.replaceChild(svg, image);
            }
          })
          .then(callback)
          .catch((error) => console.error(error));
      });
    };

    convertImages('img');
  }

  render() {
    if (this.props.posts === true) {
      /**
       * The skeleton loading component
       */
      return <div className="grid-list-view loading grid"></div>;
    } else {
      // Mimic the twig markup from rubin theme
      // so we can reuse the styles from the theme
      return (
        <div
          className={
            this.state.isLoaded
              ? 'featured-info-material layout-row medium-2-cols grid-list-view'
              : 'featured-info-material layout-row medium-2-cols grid-list-view loading'
          }
        >
          <GridListViewContext.Consumer>
            {(context) =>
              this.props.posts.map((item, i) => {
                const filmThumbUrl =
                  item.materialType === 'film' &&
                  item.infomaterial_image.infomaterialImage &&
                  item.infomaterial_image.infomaterialImage.sourceUrl;
                const articleStyle = {
                  '--grid-item-bgcolor': convertHexToRGBA(
                    item.materialkategorier.nodes[0].MaterialtypInfo.farg,
                    100
                  ),
                  '--grid-item-video-image': filmThumbUrl
                    ? `url(${filmThumbUrl})`
                    : null,
                };
                const infoRow = item.rubinInfoRow
                  ? ` - ${item.rubinInfoRow}`
                  : '';
                return (
                  <article
                    className={
                      'infomaterial-card' +
                      (filmThumbUrl ? ' film-has-background' : '')
                    }
                    key={i}
                    style={articleStyle}
                  >
                    <header>
                      <div className="text">
                        <div className="meta-info">
                          {item.materialkategorier.nodes[0].name}
                          {infoRow}
                        </div>
                        <h3>{item.title}</h3>
                        <ReadMore item={item} />
                      </div>
                      {item.infomaterial_image.infomaterialImage &&
                        item.materialType !== 'film' && (
                          <div className="infomaterial-image">
                            <img
                              srcSet={
                                item.infomaterial_image.infomaterialImage.srcSet
                              }
                              sizes={
                                item.infomaterial_image.infomaterialImage.sizes
                              }
                              src={
                                item.infomaterial_image.infomaterialImage
                                  .sourceUrl
                              }
                              alt={
                                item.infomaterial_image.infomaterialImage.title
                              }
                            />
                          </div>
                        )}
                    </header>
                    <footer>
                      <OpenMaterial item={item} />
                    </footer>
                  </article>
                );
              })
            }
          </GridListViewContext.Consumer>
        </div>
      );
    }
  }
}
