import React, { Component } from 'react'

export const GridListViewContext = React.createContext()

/**
 * GridListViewProvider can be used as a Context type of component.
 * It isn't used quite extensivly at the moment, but will momentarily.
 *
 * Just know, as said, that it's not used at the moment.
 * But is here for future use.
 */
export default class GridListViewProvider extends Component {
	render() {
  	return(
    	<GridListViewContext.Provider value={{state: this.props.state}}>
        {this.props.children}
      </GridListViewContext.Provider>
    )
  }
}
