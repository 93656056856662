import React, { Component } from 'react';

import { GridListViewContext } from './GridListViewProvider';
import OpenMaterial from './OpenMaterial';
import ReadMore from './ReadMore';

const convertHexToRGBA = (hexCode, opacity) => {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
};
export default class ListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isLoaded: true,
      });
    }, 100);
    const convertImages = (query, callback) => {
      const images = document.querySelectorAll(query);
      images.forEach((image) => {
        fetch(image.src)
          .then((res) => res.text())
          .then((data) => {
            const parser = new DOMParser();
            const svg = parser
              .parseFromString(data, 'image/svg+xml')
              .querySelector('svg');

            if (image.id) svg.id = image.id;
            if (image.className) svg.classList = image.classList;

            image.parentNode.replaceChild(svg, image);
          })
          .then(callback)
          .catch((error) => console.error(error));
      });
    };

    convertImages('img');
  }

  render() {
    return (
      <GridListViewContext.Consumer>
        {(context) => (
          <>
            <ul
              className={
                this.state.isLoaded
                  ? 'grid-list-view list'
                  : 'grid-list-view list loading'
              }
            >
              {this.props.posts.map((item, i) => {
                const listStyle = {
                  '--grid-item-bgcolor': convertHexToRGBA(
                    item.materialkategorier.nodes[0].MaterialtypInfo.farg,
                    100
                  ),
                };
                return (
                  <li key={i}>
                    <article className="infomaterial" style={listStyle}>
                      <header className="infomaterial__header">
                        <div className="infomaterial__header__wrapper">
                          <div className="infomaterial__category">
                            {item.materialkategorier.nodes[0].name}
                          </div>
                          –
                          <div className="infomaterial__info">
                            {item.rubinInfoRow}
                          </div>
                        </div>
                        <h4>{item.title}</h4>
                        <ReadMore item={item} />
                      </header>
                      <footer className="infomaterial__footer">
                        <div className="infomaterial-image">
                          {item.infomaterial_image.infomaterialImage && (
                            <img
                              srcSet={
                                item.infomaterial_image.infomaterialImage.srcSet
                              }
                              sizes={
                                item.infomaterial_image.infomaterialImage.sizes
                              }
                              src={
                                item.infomaterial_image.infomaterialImage
                                  .sourceUrl
                              }
                              alt={
                                item.infomaterial_image.infomaterialImage.title
                              }
                            />
                          )}
                        </div>
                        <OpenMaterial item={item} preventOutlineButton={true} />
                      </footer>
                    </article>
                  </li>
                );
              })}
            </ul>
          </>
        )}
      </GridListViewContext.Consumer>
    );
  }
}
