import React, { Component } from 'react';

import { GridListViewContext } from './GridListViewProvider';
import { __ } from '../i18n';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.close = this.handleBkgClick.bind(this);
  }

  handleBkgClick(e, context) {
    if (!e.target.className.includes('rims-modal-bkg')) {
      return;
    }
    context.state.modal.close();
  }

  render() {
    const modal = this.props.modal;
    if (!modal.isOpen) {
      return null;
    }
    const Content = () => modal.content;
    return (
      <GridListViewContext.Consumer>
        {(context) => (
          <div
            className="rims-modal-bkg"
            onClick={(e) => this.handleBkgClick(e, context)}
          >
            <div className="rims-modal">
              <header>
                <div className="heading">
                  {this.props.modal.title && <h4 className="modal-title" dangerouslySetInnerHTML={{__html: this.props.modal.title}}></h4>}
                  {this.props.modal.subtitle && <p className="modal-subtitle" dangerouslySetInnerHTML={{__html: this.props.modal.subtitle}}></p>}
                </div>
                <button className="close" onClick={context.state.modal.close}>
                  {__('Close')}
                </button>
              </header>
              <div className="rims-modal-inner">
                <Content />
              </div>
            </div>
          </div>
        )}
      </GridListViewContext.Consumer>
    );
  }
}
export default Modal;
