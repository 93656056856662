/**
 * translates a string by passing a key for that string defined in inc/load-data.php
 *
 * pass a substition object with each defined placeholder as key
 *
 * @param {string} str key
 * @param {object} subs optional, substitutions
 */
const __ = (str, subs = {}) => {
  if (!window.rimsLocale[str]) {
    return `[key "${str}" doesn't exist]`;
  }
  let translStr = window.rimsLocale[str];
  Object.keys(subs).forEach((key) => {
    translStr = translStr.replace(`%${key}%`, subs[key]);
  });
  return translStr;
};

export { __ };
