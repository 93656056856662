import { GridListViewContext } from './GridListViewProvider';
import React from 'react';
import { __ } from '../i18n';

function ensureArray(variable) {
  return Array.isArray(variable) ? variable : [];
}

const GridListCategoryFilter = (props) => {
  const allCategories = ensureArray(props.categories);
  const filteredCategories = props.chosencategories;
  /**
   * the `ChosenCategories` function will automatically take the passed `categories` and `chosencategories`
   * arrays coming all the way from `App.js`
   * @returns {array} Array with the all categories and their post count.
   */
  function ChosenCategories() {
    var catProps = ['materialkategoriId', 'name', 'slug'];

    var categoryResult = allCategories
      .filter(function(o1) {
        return !filteredCategories.some(function(o2) {
          return o1.materialkategoriId === o2.materialkategoriId;
        });
      })
      .map(function(o) {
        return catProps.reduce(function(newo, name, slug) {
          newo[name] = o[name];
          return newo;
        }, {});
      });

    categoryResult.forEach((element) => {
      element.count = 0;
    });

    function mergeTwo(arr1, arr2) {
      let result = [...arr1, ...arr2];
      return result.sort((a, b) => (a.name > b.name ? 1 : -1));
    }

    const finalCategoryArray = mergeTwo(categoryResult, filteredCategories);
    return finalCategoryArray;
  }

  const printedCategories = Array.isArray(filteredCategories)
    ? ChosenCategories()
    : allCategories;

  /**
   * the `SumCount` function counts all the available categories that has posts
   * coupled to it and returns the number only.
   * @returns {number} Number of total posts available to the categories under the product
   */
  function SumCount(categories) {
    return categories.reduce(
      (totalSum, item) => totalSum + item.count,
      0
    );
  }

  return (
    <div className="category-filter">
      <GridListViewContext.Consumer>
        {(context) => (
          <>
            <div className="category-filter-item" key="0">
              <a
                data-category-id=""
                onClick={context.state.chosenCategory.handleCategoryChange}
                className={`category-filter-link${
                  !window.sessionStorage.getItem('selectedCategory')
                    ? ' active-choice'
                    : ''
                }`}
              >
                <span className="link-title">{__('Show all')}</span>{' '}
                <span>({SumCount(printedCategories)})</span>
              </a>
            </div>
            {printedCategories.map((item, i) => {
              return (
                <div className="category-filter-item" key={i + 1}>
                  {item.count ? (
                    <a
                      data-category-id={item.slug}
                      style={{
                        '--category-filter-color': item.MaterialtypInfo
                          ? item.MaterialtypInfo.farg
                          : '#cacaca',
                      }}
                      className={`category-filter-link${
                        window.sessionStorage.getItem('selectedCategory') &&
                        window.sessionStorage.getItem('selectedCategory') ===
                          item.slug
                          ? ' active-choice'
                          : ''
                      }`}
                      onClick={
                        context.state.chosenCategory.handleCategoryChange
                      }
                    >
                      <span className="link-title">{item.name}</span>{' '}
                      <span className="counter">({item.count})</span>
                    </a>
                  ) : (
                    <div
                      style={{
                        '--category-filter-color': item.MaterialtypInfo
                          ? item.MaterialtypInfo.farg
                          : '#cacaca',
                      }}
                    >
                      <span className="link-title">{item.name}</span>{' '}
                      <span className="counter">(0)</span>
                    </div>
                  )}
                </div>
              );
            })}
          </>
        )}
      </GridListViewContext.Consumer>
    </div>
  );
};

export default GridListCategoryFilter;
