import {
  infoMaterialCategoriesQuery,
  infoMaterialCategoryFilterQuery,
  infoMaterialProductFilterQuery,
  infoMaterialProductandCategoryFilterQuery,
  infoMaterialProuctsQuery,
  infoMaterialQuery,
  noFilterQuery,
} from './query';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';

import GridListView from './components/GridListView';
import React from 'react';
import { __ } from './i18n';

function getCookie(key) {
  var b = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)');
  return b ? b.pop().toUpperCase() : 'DEFAULT';
}

window.localStorage.setItem('filterLanguage', getCookie('pll_language'));
const selectedLanguage = (document.documentElement.lang || 'sv')
  .split('-')[0]
  .toUpperCase();
const filterCatsByPostArray = (allPosts) => {
  const resultAsObj = {};
  const catCount = {};
  function add(arr, post) {
    const cat = post.materialkategorier.nodes[0];
    catCount[cat.materialkategoriId] = catCount[cat.materialkategoriId] || 0;
    catCount[cat.materialkategoriId]++;
    resultAsObj[cat.materialkategoriId] = cat;
    return arr;
  }

  allPosts.nodes.forEach((post) => {
    add(resultAsObj, post);
    return resultAsObj;
  });

  const result = Object.entries(resultAsObj).map(([id, cat]) => {
    return cat;
  });

  result.forEach((cat) => {
    cat.count = catCount[cat.materialkategoriId];
  });
  return result;
};

const getStorage = (key) => {
  const value = window.sessionStorage.getItem(key);
  if (typeof value === 'string') {
    return value === '' || value === 'null' ? null : value;
  }
  return null;
};

const Loading = (props) => {
  if (props.isLoading) {
    return (
      <div className="loading-screen loading-screen__filtering">
        <p>{__('Loading')}</p>
      </div>
    );
  }
  return null;
};

/**
 * HEAR YE! HEAR YE! HERE BE DRAGONS!
 *
 * There are three different types of items to keep in mind while traversing down this road:
 *
 * 1. Products (Dexcom, Control-IQ, Basal-IQ, etc...)
 * 2. Categories (Användarhistorier, Broschyrer, Bruksanvisningar, Instruktionsfilmer, Snabbguider)
 * 3. Infomaterials (The items we're filtering)
 *
 * I've tried to document and comment to the best of my abilities, cause this is a proper spaghetti bowl.
 *
 * Buckle up and brace yourselves, here we go!
 */
const App = () => {
  /**
   * the updateSelectedProduct function is called everytime we click on a product in the filter bar (e.g. Dexcom, Control-IQ etc.)
   * It passes the product id from the `data-product-id` attribute of the link clicked, which is comprised of the products database ID from WordPress.
   *
   * @param {number} product The product ID, fetched from the Wordpress Database ID for said product
   **/

  const updateSelectedProduct = (product) => {
    /**
     * If we've clicked on the 'See all' button on the product bar,
     * remove both the product and category sessionStorage keys. Then load the loadLazyAll hook, defined further down.
     *
     * ...also known as the "Clean Slate", but by user input instead of on page load...
     */
    if (product === '') {
      window.sessionStorage.removeItem('selectedProduct');
      window.sessionStorage.removeItem('selectedCategory');

      loadLazyAll();
    } else {
      /**
       * ...else we will load the selected product's infomaterials.
       */

      window.sessionStorage.setItem('selectedProduct', product); // Set a sessionStorage item with the clicked product ID.
      window.sessionStorage.removeItem('selectedCategory'); // New product means new set of categories, thus...start over on categories.

      loadLazyProducts(); // Run the Product lazy query
    }
  };

  /**
   * the updateSelectedCategory function is called everytime we click on a category in the category bar (e.g. Användarhistorier, Broschyrer etc.)
   * It passes the category slug from the `data-category-id` attribute of the category clicked.
   *
   * @param {string} category The category slug.
   **/

  const updateSelectedCategory = (category) => {
    if (category === 'Alla' || category === null) {
      /**
       * If we've clicked on "Se alla"; remove the sessionStorage key and value.
       * Thus going back to the original state, listing all available categories.
       */
      window.sessionStorage.removeItem('selectedCategory');
    } else {
      /**
       * Else, set the sessionStorage to the clicked category slug
       */
      window.sessionStorage.setItem('selectedCategory', category);
    }

    if (
      window.sessionStorage.getItem('selectedProduct') &&
      window.sessionStorage.getItem('selectedProduct') !== 'null'
    ) {
      /**
       * If a product is already selected and a category is now clicked, run the query with category + product
       * The variables passed to the function/hook below are picked from the sessionStorage items.
       */

      console.log('loadLazyCategoriesWithProduct()');
      loadLazyCategoriesWithProduct();
    } else {
      /**
       * ...else run just the Category query
       * Category slug is passed along as a variable, picked from the sessionStorage 'selectedProduct' item.
       */
      console.log('loadLazyCategoriesOnly()');
      loadLazyCategoriesOnly();
    }
  };

  /**
   * Hooks for grabbing content when product
   * and/or category buttons are clicked
   * `called`, `loading` and `data` are the different states
   * that a query go through:
   *
   * 1. `called` is when it's initialized,
   * 2. `loading`is when the data is loading.
   * 3. `data` is the populated data, ready to use.
   *
   * The names after the colon is set so that you can use multiple queries in
   * the same app. They can be set to whatever you want.
   *
   **/

  const [
    loadLazyAll,
    {
      called: allMaterialCalled,
      loading: allMaterialLoading,
      data: allMaterialData,
    },
  ] = useLazyQuery(infoMaterialQuery);

  const [
    loadLazyProducts,
    {
      called: onlyProductsFilteredCalled,
      loading: onlyProductsFilteredLoading,
      data: onlyProductsFilteredData,
    },
  ] = useLazyQuery(infoMaterialProductFilterQuery, {
    variables: {
      language: selectedLanguage,
      product: window.sessionStorage.getItem('selectedProduct'),
    },
  });

  const [
    loadLazyCategoriesOnly,
    {
      called: onlyCategoriesFilteredCalled,
      loading: onlyCategoriesFilteredLoading,
      data: onlyCategoriesFilteredData,
    },
  ] = useLazyQuery(infoMaterialCategoryFilterQuery, {
    variables: {
      category: window.sessionStorage.getItem('selectedCategory'),
      language: selectedLanguage,
    },
  });

  const [
    loadLazyCategoriesWithProduct,
    {
      called: CategoriesAndProductsFilteredCalled,
      loading: CategoriesAndProductsFilteredLoading,
      data: CategoriesAndProductsFilteredData,
    },
  ] = useLazyQuery(infoMaterialProductandCategoryFilterQuery, {
    variables: {
      category: window.sessionStorage.getItem('selectedCategory'),
      product: window.sessionStorage.getItem('selectedProduct'),
      language: selectedLanguage,
    },
  });

  /**
   * Default states (Loaded as a clean slate, e.g. when visiting the app for the first time)
   * Uses the same "state think" as above, but with an error state included
   *
   * Now, I know that I'm inconsistent with my use of the error state in the if...else statements
   * further down, I'm terribly sorry for that. Just know that I'm either using it as I should,
   * as an `else if`, or else it's just the `else` state, last in a statement.
   *
   * I will fix this in the future.
   *
   **/

  // Loading all infomaterials
  const {
    data: infoMaterialPosts,
    loading: infoMaterialsLoading,
    error: infoMaterialsError,
  } = useQuery(infoMaterialQuery, {
    variables: {
      language: selectedLanguage,
    },
  });
  // Loading all products
  const {
    data: productsPosts,
    loading: productLoading,
    error: productError,
  } = useQuery(infoMaterialProuctsQuery, {
    variables: {
      language: selectedLanguage,
    },
  });
  // Loading all infomaterial categories
  const {
    data: infoMaterialCategories,
    loading: infoMaterialCategoriesLoading,
    error: infoMaterialCategoriesError,
  } = useQuery(infoMaterialCategoriesQuery, {
    variables: {
      language: selectedLanguage,
    },
  });

  /**
   * States for when product, category or both has been chosen, done on page load.
   * Using the respective sessionStorage keys, if set. Used as a persisting state sort of thing...
   */

  // Loading all infomaterials when ONLY product has been clicked before,
  let query,
    variables = {
      language: selectedLanguage,
    };
  if (getStorage('selectedProduct')) {
    variables.product = window.sessionStorage.getItem('selectedProduct');
    if (getStorage('selectedCategory')) {
      // Loading all infomaterials when product AND category has been clicked before
      query = infoMaterialProductandCategoryFilterQuery;
      variables.category = window.sessionStorage.getItem('selectedCategory');
    } else {
      query = infoMaterialProductFilterQuery;
    }
  } else {
    if (getStorage('selectedCategory')) {
      // Loading all infomaterials when ONLY category has been clicked before,
      query = infoMaterialCategoryFilterQuery;
      variables.category = window.sessionStorage.getItem('selectedCategory');
    } else {
      query = noFilterQuery;
    }
  }
  const { data, loading, error } = useQuery(query, {
    variables,
  });

  /**
   * Lazy loaded queries (queries done when the user clicks on something.)
   * Corresponds to the lazy hooks above. Compare the variable used in the
   * statements with the names in the hooks above.
   *
   * Don't ask me why I use useEffect here, it just works.......
   *
   * `categoryArray` is used to populate the categories that are not selected.
   * This is done because of how a GraphQL query is populating the category data.
   * In its initial state, when you clicked a category, only the selected category where visible,
   * This Array puts all the empty categoies in the list, available to be used.
   */

  const getChildsNodes = (object, child) => {
    if (!object) {
      return [];
    }
    if (!object[child]) {
      return [];
    }
    return object[child].nodes || [];
  };

  if (loading) {
    return (
      <div className="loading-screen">
        <p>Laddar</p>
      </div>
    );
  }

  if (error) {
    console.error(error);
    return <div className="loading-screen">Något gick fel</div>;
  }

  if (infoMaterialsError || productError || infoMaterialCategoriesError) {
    const infoMaterialSymbol = infoMaterialsError ? '❌' : '✅';
    const productSymbol = productError ? '❌' : '✅';
    const categorySymbol = infoMaterialCategoriesError ? '❌' : '✅';
    return (
      <div className="loading-screen">
        <p>
          <span>{__('Information Material')}</span>{' '}
          <span>{infoMaterialSymbol}</span>
        </p>
        <p>
          <span>{__('Products')}</span> <span>{productSymbol}</span>
        </p>
        <p>
          <span>{__('Categories')}</span> <span>{categorySymbol}</span>
        </p>
      </div>
    );
  }

  const chosenCategories = filterCatsByPostArray(data.categoryMaterials);

  const posts = getChildsNodes(data, 'infomaterials');
  const products = getChildsNodes(productsPosts, 'produkter');
  const categories = getChildsNodes(
    infoMaterialCategories,
    'materialkategorier'
  );

  return (
    <GridListView
      categoryMethod={updateSelectedCategory}
      productMethod={updateSelectedProduct}
      posts={posts}
      categories={categories}
      chosencategories={chosenCategories}
      products={products}
    />
  );
};
export default App;
