import React, { Component } from 'react';

import { __ } from '../i18n';

class ReadMore extends Component {
  render() {
    const text = __('Direct link');
    const link = this.props.item.link || null;
    if (!link) {
      return null;
    }
    return (
      <a className="direct-link" href={link}>
        {text}
      </a>
    );
  }
}
export default ReadMore;
