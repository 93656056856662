import { GridListViewContext } from './GridListViewProvider'
import GridView from './GridView'
import ListView from './ListView'
import React from 'react'

const GridListItems = (props) => {
  return (
    <GridListViewContext.Consumer>
      {(context) =>
      (
        <>
          {/* Checks the isListView `state` in GridListView.js and sets the view accordingly */}
          {context.state.listOptions.isListView
            ? <ListView posts={props.posts} />
            :	<GridView posts={props.posts} />
          }
        </>
      )
      }
    </GridListViewContext.Consumer>
  )
}

export default GridListItems
