import { gql } from 'apollo-boost';

// ---------------------------------------------- //
// ------------ 1. STARTING QUERIES ------------- //
// ---------------------------------------------- //

/**
 * 1.1. Infomaterial Starting Query (ALL)
 */

export const infoMaterialQuery = gql`
  query infoMaterialQuery($language: LanguageCodeFilterEnum) {
    infomaterials(
      first: 1000
      where: {
        metaQuery: {
          metaArray: {
            compare: EQUAL_TO
            key: "endast_for_vardpersonal"
            value: "0"
          }
        }
        language: $language
      }
    ) {
      nodes {
        title
        link
        rubinInfoRow
        video
        pdfUrl
        materialType
        infomaterial_image {
          fieldGroupName
          infomaterialImage {
            sourceUrl
            title
            srcSet
            sizes
            altText
          }
        }
        materialkategorier {
          nodes {
            count
            materialkategoriId
            name
            slug
            MaterialtypInfo {
              farg
              ikon {
                sourceUrl
              }
            }
          }
        }
        databaseId
      }
    }
  }
`;

/**
 * 1.2. Products Starting Query (ALL)
 */

export const infoMaterialProuctsQuery = gql`
  query infoMaterialProuctsQuery($language: LanguageCodeFilterEnum) {
    produkter(
      where: {
        parent: 0
        language: $language
        orderby: { field: TITLE, order: ASC }
      }
    ) {
      nodes {
        title
        produktId
        slug
      }
    }
  }
`;

/**
 * 1.3. Infomaterials Categories Starting Query (ALL)
 */

export const infoMaterialCategoriesQuery = gql`
  query infoMaterialCategoriesQuery($language: LanguageCodeFilterEnum) {
    materialkategorier(where: { language: $language }) {
      nodes {
        count
        materialkategoriId
        name
        slug
        MaterialtypInfo {
          farg
          ikon {
            sourceUrl
          }
        }
      }
    }
  }
`;

// ---------------------------------------------- //
// -------------- /Starting Queries ------------- //
// ---------------------------------------------- //

// ---------------------------------------------- //
// ------------ 2. FILTERING QUERIES ------------ //
// ---------------------------------------------- //

/**
 * 2.1 Query for when a category is clicked (Användarberättelser, Broschyrer, etc.)
 *
 *    @param $category   Slug of the clicked category
 *
 */

export const infoMaterialCategoryFilterQuery = gql`
  query infoMaterialQuery(
    $category: [String]
    $language: LanguageCodeFilterEnum
  ) {
    infomaterials(
      first: 1000
      where: {
        taxQuery: {
          taxArray: {
            field: SLUG
            operator: IN
            taxonomy: MATERIALKATEGORI
            terms: $category
          }
        }
        metaQuery: {
          metaArray: {
            compare: EQUAL_TO
            key: "endast_for_vardpersonal"
            value: "0"
          }
        }
        language: $language
      }
    ) {
      nodes {
        title
        link
        rubinInfoRow
        video
        pdfUrl
        materialType
        infomaterial_image {
          fieldGroupName
          infomaterialImage {
            sourceUrl
            title
            srcSet
            sizes
            altText
          }
        }
        materialkategorier {
          nodes {
            count
            materialkategoriId
            name
            slug
            MaterialtypInfo {
              farg
              ikon {
                sourceUrl
              }
            }
          }
        }
        databaseId
      }
    }
    categoryMaterials: infomaterials(
      first: 1000
      where: {
        metaQuery: {
          metaArray: {
            compare: EQUAL_TO
            key: "endast_for_vardpersonal"
            value: "0"
          }
        }
        language: $language
      }
    ) {
      nodes {
        databaseId
        title
        rubinInfoRow
        video
        pdfUrl
        materialType
        infomaterial_image {
          fieldGroupName
          infomaterialImage {
            sourceUrl
            title
            srcSet
            sizes
            altText
          }
        }
        materialkategorier(where: { hideEmpty: false }) {
          nodes {
            count
            materialkategoriId
            name
            slug
            MaterialtypInfo {
              farg
              ikon {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * 2.2 Query for when a product is clicked (Basal-IQ, Dexcom, Tillbehör etc.)
 *
 *    @param $product   Product slug of the clicked product
 *
 */

export const infoMaterialProductFilterQuery = gql`
  query infoMaterialQuery($product: String, $language: LanguageCodeFilterEnum) {
    infomaterials(
      first: 1000
      where: {
        metaQuery: {
          relation: AND
          metaArray: [
            { compare: IN, key: "relation", value: $product }
            { compare: EQUAL_TO, key: "endast_for_vardpersonal", value: "0" }
          ]
        }
        language: $language
      }
    ) {
      nodes {
        databaseId
        title
        link
        rubinInfoRow
        video
        pdfUrl
        materialType
        infomaterial_image {
          fieldGroupName
          infomaterialImage {
            sourceUrl
            title
            srcSet
            sizes
            altText
          }
        }
        materialkategorier(where: { hideEmpty: false }) {
          nodes {
            count
            materialkategoriId
            name
            slug
            MaterialtypInfo {
              farg
              ikon {
                sourceUrl
              }
            }
          }
        }
      }
    }
    categoryMaterials: infomaterials(
      first: 1000
      where: {
        metaQuery: {
          relation: AND
          metaArray: [
            { compare: IN, key: "relation", value: $product }
            { compare: EQUAL_TO, key: "endast_for_vardpersonal", value: "0" }
          ]
        }
        language: $language
      }
    ) {
      nodes {
        databaseId
        title
        rubinInfoRow
        video
        pdfUrl
        materialType
        infomaterial_image {
          fieldGroupName
          infomaterialImage {
            sourceUrl
            title
            srcSet
            sizes
            altText
          }
        }
        materialkategorier(where: { hideEmpty: false }) {
          nodes {
            count
            materialkategoriId
            name
            slug
            MaterialtypInfo {
              farg
              ikon {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * 2.2 Query for when a category is clicked while a product is chosen
 *
 *    @param $product   Product slug of the clicked product
 *
 */
export const infoMaterialProductandCategoryFilterQuery = gql`
  query infoMaterialQuery(
    $product: String
    $category: [String]
    $language: LanguageCodeFilterEnum
  ) {
    infomaterials(
      first: 1000
      where: {
        metaQuery: {
          relation: AND
          metaArray: [
            { compare: IN, key: "relation", value: $product }
            { compare: EQUAL_TO, key: "endast_for_vardpersonal", value: "0" }
          ]
        }
        taxQuery: {
          taxArray: {
            taxonomy: MATERIALKATEGORI
            terms: $category
            field: SLUG
          }
        }
        language: $language
      }
    ) {
      nodes {
        databaseId
        title
        link
        rubinInfoRow
        video
        pdfUrl
        materialType
        infomaterial_image {
          fieldGroupName
          infomaterialImage {
            sourceUrl
            title
            srcSet
            sizes
            altText
          }
        }
        materialkategorier(where: { hideEmpty: false }) {
          nodes {
            count
            materialkategoriId
            name
            slug
            MaterialtypInfo {
              farg
              ikon {
                sourceUrl
              }
            }
          }
        }
      }
    }
    categoryMaterials: infomaterials(
      first: 1000
      where: {
        metaQuery: {
          relation: AND
          metaArray: [
            { compare: IN, key: "relation", value: $product }
            { compare: EQUAL_TO, key: "endast_for_vardpersonal", value: "0" }
          ]
        }
        language: $language
      }
    ) {
      nodes {
        databaseId
        title
        rubinInfoRow
        video
        pdfUrl
        materialType
        infomaterial_image {
          fieldGroupName
          infomaterialImage {
            sourceUrl
            title
            srcSet
            sizes
            altText
          }
        }
        link
        materialkategorier(where: { hideEmpty: false }) {
          nodes {
            count
            materialkategoriId
            name
            slug
            MaterialtypInfo {
              farg
              ikon {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const noFilterQuery = gql`
  query infoMaterialQuery($language: LanguageCodeFilterEnum) {
    infomaterials(
      first: 1000
      where: {
        metaQuery: {
          metaArray: {
            compare: EQUAL_TO
            key: "endast_for_vardpersonal"
            value: "0"
          }
        }
        language: $language
      }
    ) {
      nodes {
        databaseId
        title
        link
        rubinInfoRow
        video
        pdfUrl
        materialType
        infomaterial_image {
          fieldGroupName
          infomaterialImage {
            sourceUrl
            title
            srcSet
            sizes
            altText
          }
        }
        materialkategorier(where: { hideEmpty: false }) {
          nodes {
            count
            materialkategoriId
            name
            slug
            MaterialtypInfo {
              farg
              ikon {
                sourceUrl
              }
            }
          }
        }
      }
    }
    categoryMaterials: infomaterials(
      first: 1000
      where: {
        metaQuery: {
          metaArray: {
            compare: EQUAL_TO
            key: "endast_for_vardpersonal"
            value: "0"
          }
        }
        language: $language
      }
    ) {
      nodes {
        databaseId
        title
        rubinInfoRow
        video
        pdfUrl
        materialType
        infomaterial_image {
          fieldGroupName
          infomaterialImage {
            sourceUrl
            title
            srcSet
            sizes
            altText
          }
        }
        materialkategorier(where: { hideEmpty: false }) {
          nodes {
            count
            materialkategoriId
            name
            slug
            MaterialtypInfo {
              farg
              ikon {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`;

// ---------------------------------------------- //
// -------------- /Filtering Queries ------------ //
// ---------------------------------------------- //
