import React, { Component } from 'react';

import GridListHeader from './GridListHeader';
import GridListItems from './GridListItems';
import GridListViewProvider from './GridListViewProvider';
import Modal from './Modal';

export default class GridListView extends Component {
  constructor() {
    super();
    this.state = {
      modal: {
        content: null,
        isOpen: false,
        open: (newModal) => {
          this.setState((prevState) => {
            return {
              modal: {
                ...prevState.modal,
                content: newModal.content,
                title: newModal.title,
                subtitle: newModal.subtitle,
                isOpen: true,
              },
            };
          });
        },
        close: () => {
          this.setState((prevState) => {
            return {
              modal: {
                ...prevState.modal,
                isOpen: false,
              },
            };
          });
        },
      },
      listOptions: {
        isListView: false,

        /**
         * `handleViewChange` handles the toggling between the `grid` and `list` view
         * for the widget.
         * @param {Object} e - The filter view option link that the user clicked.
         */
        handleViewChange: (e) => {
          const view = e.target.getAttribute('data-type');
          const isListView = view === 'grid' ? false : true;

          this.setState((prevState) => {
            return {
              listOptions: {
                ...prevState.listOptions,
                isListView: isListView,
              },
            };
          });
        },
      },
      chosenProduct: {
        // isProductChosen: '',

        /**
         * `handleProductChange` handles the event for when a product is clicked.
         * @param {Object} e - The event that triggered this method.
         */
        handleProductChange: (e) => {
          const clickedProduct = e.target.getAttribute('data-product-id');
          this.props.productMethod(clickedProduct);

          this.setState((prevState) => {
            return {
              chosenProduct: {
                ...prevState.chosenProduct,
              },
            };
          });
        },
      },
      chosenCategory: {
        // isCategoryChosen: '',

        /**
         * `handleCategoryChange` handles the event for when a category is clicked.
         * @param {Object} e - The category link that the user clicked.
         */
        handleCategoryChange: (e) => {
          const clickedCategory = e.target.getAttribute('data-category-id');
          this.props.categoryMethod(clickedCategory);

          this.setState((prevState) => {
            return {
              chosenCategory: {
                ...prevState.chosenCategory,
              },
            };
          });
        },
      },
    };
  }

  updateBodyClass(isOpen) {
    if (isOpen) {
      document.body.classList.add('rims-modal-is-open');
    } else {
      document.body.classList.remove('rims-modal-is-open');
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if the modal was just opened or closed
    if (prevState.modal.isOpen !== this.state.modal.isOpen) {
      this.updateBodyClass(this.state.modal.isOpen);
    }
  }

  render() {
    return (
      <GridListViewProvider state={this.state} posts={this.props.posts}>
        <div className="grid-list-view">
          <Modal modal={this.state.modal} />
          <GridListHeader
            products={this.props.products}
            chosencategories={this.props.chosencategories}
            categories={this.props.categories}
          />
          <GridListItems posts={this.props.posts} />
        </div>
      </GridListViewProvider>
    );
  }
}
