import './index.scss';

import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import App from './App';
import { InMemoryCache } from 'apollo-cache-inmemory';
import React from 'react';
import ReactDOM from 'react-dom';

/**
 * We're using Apollo and it's React plugin for the GraphQL queries
 */

const client = new ApolloClient({
  uri: '/wp/graphql/',
  cache: new InMemoryCache(),
});
/**
 * Inject the whole filter function into a div width the ID #rims-root.
 * This is injected via the shortcode [rims_widget], that is specified in ./includes/shortcode.php
 */

const target = document.getElementById('rims-root');

if (target) {
  ReactDOM.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </React.StrictMode>,
    target
  );
}
